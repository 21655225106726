import { AxiosResponse } from "axios";
import { RestApi } from "./RestApi";
import { AddressDto } from "../../dto/User";

class GeocodingApi extends RestApi {
    constructor() {
        super(process.env.REACT_APP_GEO_API!);
    }

    public async suggestCities(city: string): Promise<AddressDto[]> {
        const response: AxiosResponse = await this.get(`/v1/geocode?apiKey=${process.env.REACT_APP_GEO_API_KEY!}&limit=5&types=city&q=${city}`);
        return response.data.items.map((item: any) => ({ id: item.id, ...item.address, ...item.position }));
    }
}

export const geocodingApi = new GeocodingApi();