import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { usePasswordStrength, validatePassword } from '../signup/OnboardingContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticationApi } from '../../service/api/public/AuthenticationApi';

// Composant principal
const ResetPasswordPage: React.FC = () => {
  const params = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: params.get('email'),
    code: params.get('code'),
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const { strength, feedback } = usePasswordStrength(credentials.password);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' })
  };


  const validateForm = () => {
    const newErrors = {
      password: '',
      confirmPassword: ''
    };
    let isValid = true;

    // Password validation
    const passwordValidation = validatePassword(credentials.password);
    if (!passwordValidation.isValid) {
      newErrors.password = passwordValidation.errors[0];
      isValid = false;
    }

    // Confirm password validation
    if (credentials.password !== credentials.confirmPassword) {
      newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdate = async () => {
    if (validateForm()) {
      const { confirmPassword, ...credentialsData } = credentials;
      setLoading(true);

      try {
        await authenticationApi.resetPassword({ email: credentials.email!, newPassword: credentials.password, code: credentials.code! });

        navigate("/signin")
      } catch (e: any) {
        setErrors({ ...errors, password: 'Erreur lors de la mise à jour du mot de passe' });
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-br from-white to-orange-50">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="bg-white rounded-2xl shadow-xl p-8 max-w-xl mx-auto">
          <div className="text-center">
            <div className="space-y-6">
              {/* Password field */}
              <div className="border-2 border-[#333697] rounded-lg p-6">
                <label className="block text-[#333697] text-lg font-medium mb-3">
                  Saisissez votre mot de passe
                </label>
                <div className="space-y-2">
                  <input
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
                ${errors.password ? 'border-red-500' : 'border-[#333697]'}`}
                  />
                  {/* Password strength indicator */}
                  <div className="mt-2">
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className={`h-full rounded-full transition-all duration-300 ${strength < 40 ? 'bg-red-500' :
                          strength < 60 ? 'bg-yellow-500' :
                            strength < 80 ? 'bg-blue-500' : 'bg-green-500'
                          }`}
                        style={{ width: `${strength}%` }}
                      />
                    </div>
                    {feedback.length > 0 && (
                      <ul className="mt-2 text-sm text-gray-600">
                        {feedback.map((item, index) => (
                          <li key={index} className="flex items-center">
                            <span className="mr-2">•</span>
                            {item}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              {/* Confirm Password field */}
              <div className="border-2 border-[#333697] rounded-lg p-6">
                <label className="block text-[#333697] text-lg font-medium mb-3">
                  Confirmez votre mot de passe
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={credentials.confirmPassword}
                  onChange={handleChange}
                  className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.confirmPassword ? 'border-red-500' : 'border-[#333697]'}`}
                />
                {errors.confirmPassword && (
                  <p className="mt-2 text-red-500 text-sm">{errors.confirmPassword}</p>
                )}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate();
                }}
                disabled={loading}
                type="button"
                className="w-full bg-[#333697] text-white px-6 py-3 rounded-md hover:bg-[#FC9723] transitions-color transition duration-300 text-sm font-medium"
              >
                {loading ? (
                  <div className="text-center w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    Confirmer
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;