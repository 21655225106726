import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Check, Send } from 'lucide-react';
import { newContactApi } from '../../service/api/public/NewContactApi';
import { ContactMessageDto } from '../../dto/ContactMessage';

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState<ContactMessageDto>({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');

    try {
      await newContactApi.newContact(formData);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const contactReasons = [
    "Assistance personnalisée",
    "Questions sur la plateforme",
    "Partenariats",
    "Investissements"
  ];

  return (
    <div className="bg-gradient-to-br from-white to-orange-50">

      <div className="max-w-4xl mx-auto px-4 py-16">
        {/* Main Content */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-12 bg-gradient-to-r from-[#333697] to-[#FC9723] inline-block text-transparent bg-clip-text leading-relaxed
            max-md:text-3xl max-md:mb-8 max-md:leading-relaxed">
            Contactez l'équipe Pleeso
          </h1>
          <p className="text-zinc-600 text-lg mb-6 max-md:text-base">
            Une question ? Une suggestion ? Nous sommes là pour vous aider !
          </p>
          <div className="flex justify-center gap-4 mb-8">
            {contactReasons.map((reason, index) => (
              <span key={index} className="inline-flex items-center gap-1.5 bg-white px-4 py-2 rounded-full shadow-sm text-sm text-zinc-700
                max-md:hidden">
                <Check size={14} className="text-[#FC9723]" />
                {reason}
              </span>
            ))}
          </div>

          {/* Mobile contact reasons */}
          <div className="hidden max-md:flex max-md:flex-col max-md:gap-2 max-md:mb-8">
            {contactReasons.map((reason, index) => (
              <span key={index} className="inline-flex items-center gap-1.5 bg-white px-3 py-1.5 rounded-full shadow-sm text-sm text-zinc-700">
                <Check size={14} className="text-[#FC9723]" />
                {reason}
              </span>
            ))}
          </div>
        </div>

        {/* Form Section */}
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12 max-w-xl mx-auto">
          {status === 'success' ? (
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Check className="w-8 h-8 text-green-500" />
              </div>
              <h2 className="text-2xl font-semibold mb-2">Message envoyé !</h2>
              <p className="text-zinc-600 mb-4">
                Merci de nous avoir contactés. Notre équipe reviendra vers vous dans les plus brefs délais.
              </p>
              <Link
                to="/"
                className="inline-block px-6 py-2 bg-[#333697] text-white rounded-lg hover:bg-opacity-90 transition-colors"
              >
                Retour à l'accueil
              </Link>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-zinc-700 mb-1">
                    Prénom
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="John"
                    className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-zinc-700 mb-1">
                    Nom
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Doe"
                    className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                    required
                  />
                </div>
              </div>
              {/* Email */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-zinc-700 mb-1">
                  Adresse email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="vous@example.com"
                  className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                  required
                />
              </div>

              {/* Sujet */}
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-zinc-700 mb-1">
                  Sujet
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Question sur Pleeso"
                  className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                  required
                />
              </div>

              {/* Message */}
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-zinc-700 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  placeholder="Votre message..."
                  className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none resize-none"
                  required
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={status === 'loading'}
                className="w-full bg-gradient-to-r from-[#333697] to-[#FC9723] text-white py-3 px-6 rounded-lg 
                  flex items-center justify-center gap-2 hover:opacity-90 transition-opacity disabled:opacity-50"
              >
                {status === 'loading' ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    Envoyer le message
                    <Send size={18} />
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;