import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { MatchingCriteriasDto, UserDto, UserSettingsDto } from "../../../dto/User";
import { authProvider } from "../../AuthProvider";

class UserApi extends PrivateApi {

    public async getUser(userId: string) {

        const path = `/users/${userId}`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }


    public async setUserSettings(userId: string, userSettings: UserSettingsDto) {

        const path = `/users/${userId}/settings`;
        const response: AxiosResponse = await super.put(path, userSettings);

        return response.data;
    }

    public async setMatchingCriterias(userId: string, matchingCriterias: MatchingCriteriasDto) {
        const path = `/users/${userId}/matchingcriterias`;
        const response: AxiosResponse = await super.put(path, matchingCriterias);

        return response.data;
    }

    public async updateUser(userId: string, user: UserDto) {
        const response: AxiosResponse = await super.post("/users", user);

        return response.data;
    }

    public async connectUser(userId: string) {

        const path = `/users/${userId}/online`;
        const response: AxiosResponse = await super.post(path, {});

        return response.data;
    }

    public async disconnectUser(userId: string) {

        const path = `/users/${userId}/offline`;
        const response: AxiosResponse = await super.post(path, {});

        return response.data;
    }
}

export const userApi = new UserApi();