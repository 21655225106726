import React, { useState } from 'react';
import { Check, ArrowRight, Linkedin, Twitter, Instagram, Share2, X } from 'lucide-react';
import { newContactApi } from '../../service/api/public/NewContactApi';
import { WaitListDto } from '../../dto/WaitList';

// Composant ShareModal inchangé
const ShareModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  shareLinks: Array<{ name: string; url: string; className: string }>;
  siteUrl: string;
}> = ({ isOpen, onClose, shareLinks, siteUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Inviter des amis</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={20} />
            </button>
          </div>

          <p className="text-sm text-gray-600 mb-4">
            Choisissez comment vous souhaitez partager Pleeso
          </p>

          <div className="grid grid-cols-2 gap-4 py-4">
            {shareLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`${link.className} text-white px-4 py-2 rounded-lg text-center text-sm font-medium transition-colors`}
                onClick={(e) => {
                  if (link.name === 'Email') {
                    return;
                  }
                  e.preventDefault();
                  window.open(link.url, '_blank', 'width=600,height=600');
                }}
              >
                {link.name}
              </a>
            ))}
          </div>

          <div className="bg-gray-50 p-3 rounded-lg mt-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={siteUrl}
                readOnly
                className="flex-1 bg-white px-3 py-2 text-sm rounded border"
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(siteUrl);
                }}
                className="px-3 py-2 bg-[#333697] text-white rounded hover:bg-opacity-90 text-sm"
              >
                Copier
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Composant principal WaitlistPage
const WaitlistPage: React.FC = () => {
  const INITIAL_WAITLIST_COUNT = 580;
  const [formData, setFormData] = useState<WaitListDto>({
    firstName: '',
    lastName: '',
    email: '',
    country: ''
  });
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [errorCode, setErrorCode] = useState<string>('');
  const [position, setPosition] = useState<number | null>(null);
  const [waitlistCount, setWaitlistCount] = useState(INITIAL_WAITLIST_COUNT);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('fr-FR').format(num);
  };

  const siteUrl = 'https://pleeso.com';

  const shareText = encodeURIComponent(
    "Je viens de rejoindre la liste d'attente de Pleeso, la nouvelle plateforme qui révolutionne l'entrepreneuriat ! Rejoignez-moi pour accéder en avant-première à la plateforme."
  );

  const shareToLinkedIn = () => {
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(siteUrl)}&title=${shareText}`;
    window.open(linkedinUrl, '_blank', 'width=600,height=600');
  };

  const shareLinks = [
    {
      name: 'WhatsApp',
      url: `https://wa.me/?text=${shareText}%20${siteUrl}`,
      className: 'bg-[#25D366] hover:bg-opacity-90'
    },
    {
      name: 'Email',
      url: `mailto:?subject=${encodeURIComponent('Rejoins-moi sur Pleeso !')}&body=${shareText}%20${siteUrl}`,
      className: 'bg-gray-600 hover:bg-opacity-90'
    },
    {
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${shareText}&url=${siteUrl}`,
      className: 'bg-[#1DA1F2] hover:bg-opacity-90'
    },
    {
      name: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${siteUrl}&quote=${shareText}`,
      className: 'bg-[#4267B2] hover:bg-opacity-90'
    }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    setErrorCode('');

    try {
      const count = await newContactApi.addToWaitlist(formData);
      setPosition(waitlistCount + count);
      setStatus('success');
    } catch (e: any) {
      setStatus('error');
      setErrorCode(e.message);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const benefits = [
    "Accès anticipé à la plateforme",
    "Fonctionnalités premium gratuites pendant 3 mois",
    "Accompagnement personnalisé",
    "Accès prioritaire aux investisseurs"
  ];

  return (
    <div className="bg-gradient-to-br from-white to-orange-50">
      <div className="max-w-4xl mx-auto px-4 py-16">
        {/* Main Content inchangé */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-12 bg-gradient-to-r from-[#333697] to-[#FC9723] inline-block text-transparent bg-clip-text leading-relaxed
            max-md:text-3xl max-md:mb-8 max-md:leading-relaxed">
            Rejoignez la révolution entrepreneuriale
          </h1>
          <p className="text-zinc-600 text-lg mb-6 max-md:text-base">
            Soyez parmi les premiers à accéder à Pleeso et transformez votre vision en réalité
          </p>
          <div className="flex justify-center gap-4 mb-8">
            {benefits.map((benefit, index) => (
              <span key={index} className="inline-flex items-center gap-1.5 bg-white px-4 py-2 rounded-full shadow-sm text-sm text-zinc-700
                max-md:hidden">
                <Check size={14} className="text-[#FC9723]" />
                {benefit}
              </span>
            ))}
          </div>

          {/* Mobile benefits inchangé */}
          <div className="hidden max-md:flex max-md:flex-col max-md:gap-2 max-md:mb-8">
            {benefits.map((benefit, index) => (
              <span key={index} className="inline-flex items-center gap-1.5 bg-white px-3 py-1.5 rounded-full shadow-sm text-sm text-zinc-700">
                <Check size={14} className="text-[#FC9723]" />
                {benefit}
              </span>
            ))}
          </div>
        </div>

        {/* Form Section mis à jour */}
        <div className="bg-white rounded-2xl shadow-xl p-8 max-w-xl mx-auto">
          {status === 'success' ? (
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Check className="w-8 h-8 text-green-500" />
              </div>
              <h2 className="text-2xl font-semibold mb-2">Vous êtes sur la liste !</h2>
              <div className="space-y-2 mb-4">
                <p className="text-zinc-600">
                  Vous êtes en position <span className="font-semibold text-[#333697]">#{formatNumber(position!)}</span>
                </p>
                <p className="text-sm text-zinc-500">
                  Rejoignez les {formatNumber(position!)} entrepreneurs qui font déjà confiance à Pleeso
                </p>
              </div>
              <p className="text-zinc-600 mb-4">
                Partagez Pleeso avec votre réseau pour faire partie des premiers à accéder à la plateforme !
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <button
                  onClick={shareToLinkedIn}
                  className="px-4 py-2 bg-[#0A66C2] text-white rounded-lg hover:bg-opacity-90 transition-colors flex items-center justify-center gap-2"
                >
                  <Linkedin size={20} />
                  Partager sur LinkedIn
                </button>
                <button
                  onClick={() => setIsShareModalOpen(true)}
                  className="px-4 py-2 bg-[#FC9723] text-white rounded-lg hover:bg-opacity-90 transition-colors flex items-center justify-center gap-2"
                >
                  <Share2 size={20} />
                  Inviter un ami
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-zinc-700 mb-1">
                    Prénom
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="John"
                    className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-zinc-700 mb-1">
                    Nom
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Doe"
                    className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-zinc-700 mb-1">
                  Adresse email
                </label>
                {status === 'error' && errorCode === 'DUPLICATED_EMAIL' && (
                  <div className="block text-sm font-bold text-red-700 mb-1">
                    * Cette adresse e-mail est déjà dans la liste
                  </div>
                )}
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="vous@example.com"
                  className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                  required
                />
              </div>
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-zinc-700 mb-1">
                  Pays
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  placeholder="France"
                  className="w-full px-4 py-3 rounded-lg border border-zinc-200 focus:ring-2 focus:ring-[#FC9723] focus:border-transparent outline-none"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={status === 'loading'}
                className="w-full bg-gradient-to-r from-[#333697] to-[#FC9723] text-white py-3 px-6 rounded-lg 
                  flex items-center justify-center gap-2 hover:opacity-90 transition-opacity disabled:opacity-50"
              >
                {status === 'loading' ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    Rejoindre la liste d'attente
                    <ArrowRight size={18} />
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>

      {/* Share Modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareLinks={shareLinks}
        siteUrl={siteUrl}
      />
    </div>
  );
};

export default WaitlistPage;