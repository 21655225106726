import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { Buffer } from 'buffer';
import { FileDto } from "../../../dto/File";

class FilesApi extends PrivateApi {

    constructor() {
        super(process.env.REACT_APP_PRIVATE_FILES_API!);
    }

    public async getProfileImage(userId: string) {

        const path = `files?path=profiles/${userId}`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async sendFile(receivers: string[], file: File) {
        const buffer = Buffer.from(await file.arrayBuffer());
        const base64String = buffer.toString('base64');

        const path = `files/message`;
        const response: AxiosResponse = await super.post(path, {
            receivers,
            name: file.name,
            contentType: file.type,
            contentLength: file.size,
            data: base64String,
            base64Encoded: true
        });

        return response.data;
    }

    public async downloadFile(filePath: string): Promise<FileDto> {

        const path = `files?path=${filePath}`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }
}

export const filesApi = new FilesApi();