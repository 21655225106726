import { AxiosResponse } from "axios";
import { PrivateApi } from "./PrivateApi";
import { ConversationDto, MessageByDayDto, MessageDto, UserLinksDto } from "../../../dto/Contact";
import moment from "moment";

class ContactsApi extends PrivateApi {

    public async getUserLinks(userId: string): Promise<UserLinksDto> {

        const path = `/contacts/${userId}`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async getConversations(userId: string): Promise<ConversationDto[]> {

        const path = `/messages/${userId}`;
        const response: AxiosResponse = await super.get(path);
        const conversations = response.data.map((c: any) => ({ ...c, messages: this.toGroupedMessages(c.messages) }));
        return conversations;
    }

    public toGroupedMessages(messages?: MessageDto[]): MessageByDayDto[] {
        if (!messages) {
            return [];
        }

        const groupedByDay = messages.reduce((acc: MessageByDayDto[], msg: MessageDto) => {
            const day = moment(msg.sendDate).format('MMMM Do YYYY');

            let sendDate = moment(msg.sendDate).format('hh:mm');

            const dayMsgs = acc.find(a => a.day === day);

            if (dayMsgs) {
                dayMsgs.messages.push({ ...msg, sendDate });
            } else {
                acc.push({ day, messages: [{ ...msg, sendDate }] });
            }
            return acc;
        }, [] as MessageByDayDto[]);

        return groupedByDay;
    }

    public async sendMessage(userId: string, newMsg: MessageDto) {

        const path = `/messages/${userId}/new`;
        const response: AxiosResponse = await super.post(path, newMsg);

        return response.data;
    }

    public async createRoom(userId: string, userIds: string[]) {

        const path = `/messages/${userId}/room`;
        const response: AxiosResponse = await super.post(path, { userIds });

        return response.data;
    }

    public async setSeenStatus(userId: string, roomId: string, messageIds: number[]) {

        const path = `/messages/${userId}/room/${roomId}/seen`;
        const response: AxiosResponse = await super.post(path, messageIds);

        return response.data;
    }

    public async getRequests(userId: string) {

        const path = `/contacts/${userId}/requests`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async getConnectedUsers(userId: string) {

        const path = `/contacts/${userId}/connected`;
        const response: AxiosResponse = await super.get(path);

        return response.data;
    }

    public async favorite(userId: string, newFavoriteId: string) {

        const path = `/contacts/${userId}/favorite`;
        const response: AxiosResponse = await super.post(path, newFavoriteId);

        return response.data;
    }

    public async ignore(userId: string, newIgnoredId: string) {

        const path = `/contacts/${userId}/ignore`;
        const response: AxiosResponse = await super.post(path, newIgnoredId);

        return response.data;
    }

    public async newContactRequest(userId: string, receiverId: string) {

        const path = `/contacts/${userId}/request`;
        const response: AxiosResponse = await super.post(path, receiverId);

        return response.data;
    }

    public async acceptContactRequest(userId: string, requesterId: string) {

        const path = `/contacts/${userId}/accept`;
        const response: AxiosResponse = await super.post(path, requesterId);

        return response.data;
    }

    public async deleteFavorite(userId: string, favoriteId: string) {

        const path = `/contacts/${userId}/favorite/${favoriteId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }

    public async deleteIgnore(userId: string, ignoredId: string) {

        const path = `/contacts/${userId}/ignore/${ignoredId}`;
        const response: AxiosResponse = await super.delete(path);

        return response.data;
    }
}

export const contactsApi = new ContactsApi();