import { AxiosResponse } from "axios";
import { PublicApi } from "./PublicApi";
import { WaitListDto } from "../../../dto/WaitList";
import { ContactMessageDto } from "../../../dto/ContactMessage";

class NewContactApi extends PublicApi {

    public async addToWaitlist(waitlist: WaitListDto) {
        const response: AxiosResponse = await super.post("/public/db/waitlist", waitlist);
        return response.data;
    }

    public async addToNewsletter(email: string) {
        const response: AxiosResponse = await super.post("/public/db/newsletter", { email, attributes: {} });
        return response.data;
    }

    public async newContact(contact: ContactMessageDto) {
        const response: AxiosResponse = await super.post("/public/db/contact", contact);
        await super.post("/public/mail/contact", {
            templateId: 'contact',
            from: contact.email,
            subject: contact.subject,
            context: {
                subject: contact.subject,
                message: contact.message,
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email
            }
        });

        return response.data;
    }
}

export const newContactApi = new NewContactApi();