const competences = ["Banque d'investissement",
    "Gestion de patrimoine",
    "Services financiers",
    "Analyse de crédit"]

export const getCompetencesSuggestions = competences.map((c, index) => ({
    id: c,
    value: c,
}));


